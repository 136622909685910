import * as React from 'react';
import { useState } from 'react';
import TitanT from "../../assets/icon/favicon.png";


import {
    IonButton,
    IonCard, IonCardHeader,
    IonContent, IonFooter, IonImg, IonInput, IonLabel, IonPage, useIonToast
} from '@ionic/react';

import { useHistory } from 'react-router';
import AuthProvider, { resetPassword } from "../../services/contexts/AuthContext/AuthContext";
import './Login.css';

const ForgotPassword: React.FC = () => {
   


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const history = useHistory();
  const [signup, setSignup] = useState(false);
  const [present, dismiss] = useIonToast();

  async function login() {
    // try {
    //   await emailLogin(email, password);
    //   clear();
    //   history.push("/home");
    // } catch (e: any) {
    //   present({
    //     buttons: [{ text: "dismiss", handler: () => dismiss() }],
    //     message: e,
    //     duration: 5000,
    //     color: "danger",
    //   });
    // }
  }

  async function signupClick() {
    // if (password === checkPassword) {
    //   try {
    //     const userCredential = await emailSignup(email, password);
    //     await createUser(userCredential);
    //     clear();
    //     history.push("/home");
    //   } catch (e: any) {
    //     present({
    //       buttons: [{ text: "dismiss", handler: () => dismiss() }],
    //       message: e,
    //       duration: 5000,
    //       color: "danger",
    //     });
    //   }
    // } else {
    //   present({
    //     buttons: [{ text: "dismiss", handler: () => dismiss() }],
    //     message: "Passwords do not match.",
    //     duration: 5000,
    //     color: "danger",
    //   });
    // }
  }

  function clear() {
    setEmail("");
    setPassword("");
    setCheckPassword("");
  }

  function onResetButtonClicked()
  {
    resetPassword(email).then(() => {
        present({
            buttons: [{ text: "dismiss", handler: () => dismiss() }],
            message:'A link to reset your password has been sent to '+email,
            duration: 5000,
            color: "success",
          });

          clear();
      history.push("/login");
          //Redirect to login
        
    }).catch((err) => {
        present({
            buttons: [{ text: "dismiss", handler: () => dismiss() }],
            message: err.message,
            duration: 5000,
            color: "danger",
          });
    });
  }



  return (
    <AuthProvider>
      <IonPage>
        <IonContent color="light">
          <div className="background" />
          <div className="card--container">
            <IonCard
              className="card--login ion-padding ion-margin"
              color="primary"
            >
              <IonCardHeader className="titan-container">
                <IonImg className="titan-t" src={TitanT} />
                <IonLabel color="light">Reset Password</IonLabel>
              </IonCardHeader>
              <div className='forgot-desc'>
              <IonLabel className='link' >Enter the email address associated with your account.</IonLabel>
              </div>

              <IonLabel color="light" position="floating">
                Email:{" "}
              </IonLabel>
              <IonInput
                className="login-input"
                value={email}
                onIonChange={(val) => {
                  const inputEmail = val.detail.value;
                  if (inputEmail) {
                    setEmail(inputEmail);
                  }
                }}
              />
          
              <IonFooter className="buttons-footer">
              <IonButton
                  color="secondary"
                  onClick={() => {
                    onResetButtonClicked();
                  }}
                >
                  Reset
                </IonButton>
              <IonButton color="secondary"  href="/login">Back to Login</IonButton>
                 
              
              </IonFooter>
            </IonCard>
          </div>
        </IonContent>
      </IonPage>
    </AuthProvider>
  );

                     
};

export default ForgotPassword;
